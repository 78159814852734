import getConfig from '@/config'
import * as Sentry from '@sentry/nextjs'
import Cookie from 'js-cookie'
import {
  FullStory,
  init as initFullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser'

import { forEach, fromPairs, keys, map, pipe, toPairs } from 'ramda'

import * as output from '../../lib/console'
import { addScript } from '../../lib/dom'

import callAPI from '../api/call'
import { getBranch } from '../branch'
import { createMedShrTracker } from './medshr-analytics'

const config = getConfig().publicRuntimeConfig
const IS_LOGGING =
  config.debugLog ||
  (global.document && /debugLog/.test(global.document.location.search))

const disableTracking =
  typeof window !== 'undefined' &&
  window['navigator'].userAgent &&
  /(medshr.net test runner|burpsuite|zaproxy|googlesecurityscanner)/i.test(
    window['navigator']['userAgent']
  )

let [_medshr, gaInitialised] = [null, false]

const log = (service, ...args) => {
  if (IS_LOGGING) output.log(service, ...args)
  Sentry.addBreadcrumb({
    message: service,
    data: args || {},
  })
}

const shouldSendtoSentry = ex => {
  if (typeof window === 'undefined') {
    return false
  }

  const e = ex?.toString() ?? ''
  if (e.length === 0) {
    return false
  }
  if (
    /(Request blocked by client|Error in API: 0|Request has been terminated|Request Cancelled|Failed to fetch)/.test(
      e
    )
  ) {
    return false
  }
  return !(
    e.indexOf('ReferenceError: $jscomp is not defined') > -1 &&
    typeof navigator !== 'undefined' &&
    /GoogleSecurityScanner/.test(navigator.userAgent)
  )
}

const error = (ex, context?) => {
  shouldSendtoSentry(ex) &&
    Sentry.captureException(typeof ex === 'string' ? new Error(ex) : ex, {
      extra: context, // noCirculars({ ...context }),
    })
  output.error(ex, context)
}

const ignoreError = f => {
  return (...args) => {
    try {
      return f(...args)
    } catch (e) {
      output.error(e)
    }
  }
}

const noCirculars = v => {
  const set = new Set()
  const noCirculars = v => {
    if (Array.isArray(v)) return v.map(noCirculars)
    if (typeof v === 'object' && v !== null) {
      if (set.has(v)) return '[circular]'
      set.add(v)
      return pipe(
        toPairs,
        map(pair => [pair[0], noCirculars(pair[1])]),
        fromPairs
      )(v)
    }
    return v
  }
  return noCirculars(v)
}

const medshr = () => {
  return _medshr || (_medshr = createMedShrTracker(config.medshr_analytics))
}

const gtag = function (...args) {
  if (config.env !== 'production') {
    log('GA:', ...args)
  }
  try {
    window['dataLayer'].push(arguments)
  } catch (e) {
    console.error(e)
  }
}

const ga = () => {
  if (config.ga && config.ga.id && !disableTracking) {
    if (!gaInitialised) {
      gaInitialised = true
      gtag('js', new Date())
      gtag('config', config.ga.id, {
        send_page_view: false,
        custom_map: {
          dimension3: 'guest',
          dimension4: 'guest',
        },
      })
      if (Array.isArray(config.gtag_ids)) {
        config.gtag_ids.forEach(id => gtag('config', id))
      }
    }
    return {
      config: x => gtag('config', config.ga.id, x),
      event: (action, args) => gtag('event', action, args),
    }
  }
  return {
    config: x => log('GA: config', x),
    event: (action, args) => log('GA:', action, args),
  }
}

const fullstory = () => {
  const guardEventName = callback => (name, properties) => {
    if (name.startsWith('Next.js')) {
      return
    }
    return callback(name, properties)
  }
  if (config.fullStory?.orgId && !disableTracking) {
    if (!isFullStoryInitialized()) {
      initFullStory(config.fullStory)
    }
    return {
      identify: (user, properties) =>
        FullStory('setIdentity', {
          uid: user.id.toString(),
          properties: {
            displayName: user.title,
            ...mapFullStoryProperties(properties),
          },
        }),
      event: guardEventName((name, properties) =>
        FullStory('trackEvent', {
          name,
          properties: mapFullStoryProperties(properties),
        })
      ),
      userProperties: properties =>
        FullStory('setProperties', { type: 'user', properties }),
      pageProperties: properties =>
        FullStory('setProperties', { type: 'page', properties }),
    }
  }
  return {
    identify: (user, properties) =>
      console.log('FullStorySDK.identify', user.id.toString(), {
        displayName: user.title,
        ...mapFullStoryProperties(properties),
      }),
    event: guardEventName((name, properties) =>
      console.log(
        'FullStorySDK.event',
        name,
        mapFullStoryProperties(properties)
      )
    ),
    userProperties: properties =>
      console.log('FullStorySDK.setProperties', properties),
  }
}

const registerProperties = properties => {
  getBranch().then(branch => {
    const branchData = branch.data()
    properties = properties || {}
    properties['platform'] = 'web'
    var redirect_to
    if (branchData) {
      if (
        !branchData.data_parsed &&
        typeof branchData.data === 'string' &&
        branchData.data.length > 0
      ) {
        branchData.data_parsed = JSON.parse(branchData.data)
      }
      if (branchData.data_parsed) {
        if (branchData.data_parsed['$marketing_title']) {
          properties['branch_link_title'] =
            branchData.data_parsed['$marketing_title']
        }
        if (branchData.data_parsed['~id']) {
          properties['branch_link_id'] = branchData.data_parsed['~id']
        }
        if (
          branchData.data_parsed['$desktop_url'] === '' &&
          branchData.data_parsed['$fallback_url'] === '' &&
          branchData.data_parsed['id']
        ) {
          switch (branchData.data_parsed['screen_id']) {
            case 'resource':
              switch (branchData.data_parsed['type']) {
                case 'case':
                case 'group':
                case 'institution':
                  redirect_to =
                    '/' +
                    branchData.data_parsed['type'] +
                    's/' +
                    branchData.data_parsed['id']
                  break
              }
              break
            case 'group':
            case 'institution':
              redirect_to =
                '/' +
                branchData.data_parsed['type'] +
                's/' +
                branchData.data_parsed['id']
              break
            case 'user':
              redirect_to = '/members/' + branchData.data_parsed['id']
              break
          }
        }
      }
    }
    medshr().register(properties)
    if (redirect_to) {
      setTimeout(function () {
        const location = global.location
        location.href = location.protocol + '//' + location.host + redirect_to
      }, 500)
    }
  })
}

const when = (check, callback) => {
  function timedCheck() {
    if (check()) {
      callback()
    } else {
      setTimeout(timedCheck, 250)
    }
  }

  timedCheck()
}

const identify = (user, properties, method = 'identify') => {
  const user_id = user.id.toString()
  Sentry.setUser({ ...properties, id: user_id })

  const { gacd, ...superProps } = properties

  medshr()[method](user_id)
  fullstory().identify(user, properties)

  ga().config({ user_id })
  if (gacd && gacd.dimensions) {
    ga().config({
      custom_map: fromPairs(
        map(([k, v]) => [`dimension${k}`, `${k}`], toPairs(gacd.dimensions))
      ),
    })
  }

  getBranch().then(branch => {
    branch.setIdentity(user_id)
    const data = branch.data()
    const parsed =
      data && typeof data.data === 'string' && data.data.length > 0
        ? JSON.parse(data.data)
        : data?.data_parsed
    if (config.debug && IS_LOGGING) {
      console.log('getBranchData identify', data, parsed)
    }
    if (parsed && parsed['~id']) {
      if (!Cookie.get('s-' + parsed['~id'])) {
        callAPI({
          method: 'POST',
          url: '/api/profile/prepare',
          json: { branch: parsed },
        }).then(
          () => {
            Cookie.set('s-' + parsed['~id'], '1')
            if (global.document) {
              global.document.location.reload()
            }
          },
          ({ error }) => {
            console.error(error)
          }
        )
      }
    }
  })

  registerProperties(superProps)
}

const logout = () => {
  event('Auth', 'Logout')
}

const view = (page_path, page_title) => {
  ga().config({ page_path, page_title })
  medshr().track('Page - View', { label: page_path, content_title: page_title })
  if (typeof window !== 'undefined' && typeof window['fbq'] === 'function') {
    window['fbq']('track', 'PageView')
  }
}

const dedupe = {}

const trackClientEvents = events => {
  if (config.debug && IS_LOGGING) {
    output.log('trackClientEvents', events)
  }
  return events.map(({ category, action, ...props }) =>
    event(category, action, props)
  )
}

const event = (category, action, properties = {}) => {
  const k = JSON.stringify([category, action, properties])
  const now = new Date().getTime()
  if (dedupe[k] && dedupe[k] > now - 5000) {
    return
  }
  dedupe[k] = now

  forEach(k => {
    if (dedupe[k] < now - 5000) {
      delete dedupe[k]
    }
  }, keys(dedupe))

  let args: any = {
    event_category: category,
  }

  if (action === 'conversion') {
    args = { ...properties }
  }

  if (typeof properties === 'string' || typeof properties === 'number') {
    args.event_label = '' + properties
    properties = { label: '' + properties }
  } else if (typeof properties === 'object' && properties['Content ID']) {
    args.event_label = '' + properties['Content ID']
  } else if (typeof properties === 'object' && properties['label']) {
    args.event_label = '' + properties['label']
  }

  if (typeof properties === 'object' && properties['value']) {
    args.event_value = properties['value']
  }

  if (category !== 'Feed Item') {
    ga().event(action, args)

    fullstory().event(category + ' - ' + action, properties)
  }

  medshr().track(category + ' - ' + action, properties)

  if (category !== 'Feed Item') {
    Sentry.addBreadcrumb({
      message: action,
      category: category,
      data: properties,
    })
  }
}

const mapFullStoryProperties = (props?: {
  [k: string]: string | boolean | number
}): { [k: string]: string | boolean | number } => props
// ? fromPairs(
//     map(([k, v]) => {
//       if (k === 'displayName') {
//         return []
//       }
//       if (typeof v === 'boolean') {
//         return [`${k}_bool`, v]
//       }
//       if (typeof v === 'number') {
//         if (Math.round(v) === v) {
//           return [`${k}_int`, v]
//         }
//         return [`${k}_real`, v]
//       }
//       if (typeof v === 'string') {
//         return [`${k}_str`, v.toString()]
//       }
//       return []
//     }, toPairs(props)).filter(x => x.length === 2)
//   )
// : undefined

const jwt = jwt => {
  medshr().jwt(jwt)
}

const tokens = tokens => {
  medshr().tokens(tokens)
}

const installSessionScripts = (scripts: { [id: string]: string }) => {
  if (config.debug && IS_LOGGING) {
    output.log('installSessionScripts', scripts)
  }
  Object.keys(scripts).map(id => addScript(scripts[id], id, 15000))
}

const init = () => {
  fullstory()
  medshr()
  ga()
  getBranch()
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export {
  jwt,
  tokens,
  event,
  trackClientEvents,
  view,
  log,
  logout,
  identify,
  error,
  installSessionScripts,
  init,
  fullstory,
}
